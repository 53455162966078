import React, { useEffect, useState, useContext, useMemo } from 'react'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Search, { SearchSelect, SearchText } from '#Search'

const dateDisplay = ({ invoiceLastDate }) => {
	if(!invoiceLastDate)
		return '-- mai --'
	const str = invoiceLastDate.toString()
	return str.slice(4, 6) + '/' + str.slice(6) + '/' + str.slice(0, 4)
}

const tabDef = [
	{ label:"ID Ag.", field:"agent", isSortable:true },
	{ label:"Agente", field:"agentName" },
	{ label:"Codice", field:"code", isSortable:true },
	{ label:"Denominazione", field:"name", isSortable:true },
	{ label:"P.IVA", field:"vat", isSortable:false },
	{ label:"Località", field:"addrLoc", isSortable:true },
	{ label:"Prov.", field:"addrProv", isSortable:true },
	{ label:"Ultima fatt.", content:dateDisplay },
	{ label:"Fatt. anno corrente", content:row => row?.revenues?.yb0*10000, type:'money', isSortable:false },
	{ label:"Fatt. anno prec.", content:row => row?.revenues?.yb1*10000, type:'money', isSortable:false },
]

const optsRevenues = [
	{ value:"all", label:"Tutti" },
	{ value:"0", label:"Solo con fatturato nell'anno corrente" },
	{ value:"1", label:"Solo con fatturato nell'anno corrente e precedente" },
	{ value:"8", label:"Solo con fatturato negli ultimi 8 anni" },
	{ value:"-8", label:"Nessun fatturato negli ultimi 8 anni" },
]

const useAreas = () => {
	const api = useContext(APICtx)
	return useMemo(() => {
		const areas = [
			{ label:'-- Tutte --', value:'-' }
		]
		if(api.auth.chk('sede'))
			areas.push({ value:'01', label:'Sede' })
		if(api.auth.chk('friuli'))
			areas.push({ value:'02', label:'Friuli' })
		//TODO euroacciai?
		return areas
	}, [])
}

const agentOptAll = { label:'-- Tutti --', value:'-' }

export default function CustomerList() {
	const [ srcCustomer, setSrcCustomer ] = useState('')
	const [ srcProvince, setSrcProvince ] = useState('')
	const [ srcAgent, setSrcAgent ] = useState('-')
	const [ srcArea, setSrcArea ] = useState('-')
	const [ srcRevenues, setSrcRevenues ] = useState('all')
	const [ agentOpts, setAgentOpts ] = useState([ agentOptAll ])
	const [ ids, setIds ] = useState([])
	const api = useContext(APICtx)

	const aclSupervisor = api.auth.chkAny([ 'sede', 'euroacciai', 'friuli' ])

	const areas = useAreas()
	const handleRefresh = () => api.call('customer/list', { srcAgent, srcArea, srcCustomer, srcProvince, srcRevenues }).then(setIds)

	useEffect(() => {
		aclSupervisor && api.call('agent/options')
			.then(opts => setAgentOpts([ agentOptAll, ...opts ]))
	}, [])
	useEffect(() => {
		handleRefresh()
	}, [ srcAgent, srcArea, srcCustomer, srcRevenues, srcProvince ])

	const getRow = _id => api.call('customer/get/'+_id)

	return (
		<Tpl title="Lista clienti">
			<Search>
				<SearchText label="Cliente" name="customer" onChange={setSrcCustomer} />
				<SearchText label="Provincia" name="province" onChange={setSrcProvince} />
				{ aclSupervisor && <SearchSelect label="Agente" name="agente" onChange={setSrcAgent} options={agentOpts} /> }
				{ aclSupervisor && <SearchSelect label="Divisioni" name="area" onChange={setSrcArea} options={areas} /> }
				<SearchSelect label="Fatturato" name="revenues" onChange={setSrcRevenues} options={optsRevenues} />
			</Search>
			<Table def={tabDef} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</Tpl>
	)
}