import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Search({ children }) {
	return (
		<Box sx={{ width:"100%", mb:1 }}>
			{children}
		</Box>
	)
}

const useQueryText = ({ name }) => {
	const query = useQuery()
	return React.useMemo(
		() => query.get(name) || '',
		[query, name]
	)
}

const useQueryDateInt = ({ name }) => {
	const query = useQuery()
	return React.useMemo(() => {
		const value = {
			from: query.get(name+'-from'),
			to: query.get(name+'-to'),
		}
		Object.keys(value).forEach(vKey => !value[vKey] && (delete value[vKey]))
		return value
	}, [query, name])
}

export const SearchDateInt = ({ name, onChange, label }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const value = useQueryDateInt({ name })

	const handleChange = (sub, e) => {
		query.set(name+'-'+sub, e.target.value)
		navigate('?'+query.toString())
	}
	useEffect(() => onChange?.(value), [ value, onChange ])
	
	const labelFrom = label ? label+": dal" : "Dal"
	const labelTo = label ? label+": al" : "Al"
	return (
		<Grid container sx={{ mb:1 }}>
			<Grid item xs={6}>
				<TextField
					fullWidth
					type="date"
					value={value.from || ''}
					label={labelFrom}
					onChange={e => handleChange('from', e)}
					InputLabelProps={{ shrink:true }}
				/>
			</Grid>
			<Grid item xs={6}>
			<TextField
					fullWidth
					type="date"
					value={value.to || ''}
					label={labelTo}
					onChange={e => handleChange('to', e)}
					InputLabelProps={{ shrink:true }}
				/>
			</Grid>
		</Grid>
	)
}

export const SearchText = ({ name, onChange, label }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const value = useQueryText({ name })

	const handleChange = e => {
		query.set(name, e.target.value)
		navigate('?'+query.toString())
	}
	useEffect(() => onChange?.(value), [ value, onChange ])
	
	return (
		<TextField
			fullWidth
			type="text"
			value={value || ''}
			label={label}
			onChange={handleChange}
			sx={{ mb:1 }}
		/>
	)
}

export const SearchSelect = ({ name, onChange, label, options, defaultValue='', emptyLabel }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const value = useQueryText({ name }) || defaultValue

	const handleChange = e => {
		query.set(name, e.target.value || defaultValue)
		navigate('?'+query.toString())
	}
	useEffect(() => onChange?.(value), [ value, onChange ])
	
	return (
		<FormControl fullWidth>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				value={value}
				label={label}
				onChange={handleChange}
				sx={{ mb:1 }}
			>
				{ Boolean(emptyLabel) && <MenuItem key="_empty" value="">{emptyLabel}</MenuItem> }
				{ options.map((opt, optIdx) => (
					<MenuItem key={optIdx} value={opt.value}>{opt.label || opt.value}</MenuItem>
				)) }
			</Select>
		</FormControl>
	)
}
