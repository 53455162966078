import React, { useEffect, useState, useContext } from 'react'
import { Typography } from '@mui/material'


import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
// import Button from '#Button'
import Fab from '#Fab'
// import Search, { SearchText } from '#Search'
import { faPlus, faEdit } from '@fortawesome/pro-regular-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// const drawQty = (qty, measureUnit) => {
// 	if(qty === undefined)
// 		return <FontAwesomeIcon icon={faQuestion} />
// 	if(qty === null)
// 		return <FontAwesomeIcon icon={faTriangleExclamation} color='red' />
// 	return (qty/10000).toString() + ' ' + measureUnit
// }

// const ItemQty = ({ code, endpoint, measureUnit }) => {
// 	const api = useContext(APICtx)
// 	const [ qty, setQty ] = useState(undefined)

// 	const handleLoad = () => api.call('smeup/qa/' + endpoint, { itemID:code }).then(ret => {
// 		setQty(ret?.hasOwnProperty('qty') ? ret.qty : null)
// 	}).catch(() => setQty(null))

// 	return (
// 		<Typography>
// 			{drawQty(qty, measureUnit)}
// 			{ (qty===undefined || qty===null) && <Button icon={faSync} tooltip="Calcola valore" onClick={handleLoad} /> }
// 		</Typography>
// 	)
// }

const tabDef = [
	{ label:"Matricola", field:"serialNumber" },
	{ label:"Nome", field:"label" },
	{ label:"Raggiungibile", field:"isAlive", type:"boolean" },
	{ label:"Indirizzo IP", field:"ipAddress" },
	{ label:"Indirizzo OPC-UA", field:"opcuaAddress" },
	{ label:"Tipologia", field:"machineType" },
	{ label:"DB Smeup", field:"smeupDB" },
]

export default function MechList() {
	const [ ids, setIds ] = useState([])
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('ind40/mech/list').then(setIds)

	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('ind40/mech/get', { _id })

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => "/ind40/mech/edit/" + row._id,
		},
	]

	return (
		<Tpl title="Industria 4.0 - Macchine">
			<DataList def={tabDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
			<Fab label="Aggiungi macchina" icon={faPlus} linkTo="/ind40/mech/edit" />
		</Tpl>
	)
}
