import React, { useEffect, useState, useContext } from 'react'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'

const tabDef = [
	{ label:"Codice", field:"code", isSortable:true },
	{ label:"Denominazione", field:"name", isSortable:true },
	{ label:"Num. clienti", field:"customersQty", },
	{ label:"Preferito", field:"activeDefault", type:'bool', isSortable:true },
	{ label:"Colore", field:"color", type:'color' },
]

export default function CustomerList() {
	const [ ids, setIds ] = useState([])
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('agent/list').then(setIds)

	useEffect(() => { handleRefresh() }, [])

	const getRow = _id => api.call('agent/get/'+_id)
	const defBtns = [
		{ icon:faEdit, label:'Modifica', linkTo:row => '/agent/edit/'+row._id },
	]

	return (
		<Tpl title="Lista agenti">
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</Tpl>
	)
}