import React, { useMemo } from 'react';
import { Routes, Route } from "react-router-dom";
import { Box, Typography } from '@mui/material';

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
// import logoImg from './logo.png'


import Ind40MechListPage from './ind40/MechList'
import Ind40MechEditPage from './ind40/MechEdit'
import Ind40OdlListPage from './ind40/OdlList'
import Ind40CutListPage from './ind40/CutList'

import CustomerListPage from './pages/CustomerList'
import ItemListPage from './pages/ItemList'
import AgentListPage from './pages/AgentList'
import AgentEditPage from './pages/AgentEdit'
import GeomapPage from './pages/Geomap'
import { faUsers, faSuitcase, faMapMarked, faBoxesStacked, faConveyorBelt } from '@fortawesome/pro-solid-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/customer/list" element={<CustomerListPage />} />
		<Route path="/item/list" element={<ItemListPage />} />
		<Route path="/agent/list" element={<AgentListPage />} />
		<Route path="/agent/edit/:_id" element={<AgentEditPage />} />
		<Route path="/geomap" element={<GeomapPage />} />
		
		<Route path="/ind40/mech/edit/:_id" element={<Ind40MechEditPage />} />
		<Route path="/ind40/mech/edit" element={<Ind40MechEditPage />} />
		<Route path="/ind40/mech/list" element={<Ind40MechListPage />} />
		
		<Route path="/ind40/odl/list" element={<Ind40OdlListPage />} />
		
		<Route path="/ind40/cut/list" element={<Ind40CutListPage />} />
		
		<Route path="/" element={<HomePage />} />
	</Routes>
)

const HomePage = () => {
	const imgStyle = {
		display: 'block',
		margin: '0 auto',
	}
	return (
		<Tpl title="Home">
			<Typography sx={{ mb:5 }} variant="h5">Benvenuto nel portale servizi di Orlandi Acciai S.p.A.</Typography>
			<img style={imgStyle} src="/logo_orlandi.png" />
			<img style={imgStyle} src="/logo_euroacciai.png" />
		</Tpl>
	)
}

const useMenu = (api) => useMemo(() => {
	const menuGeo = []
	if(api.auth.chkAny([ 'user' ]))
		menuGeo.push({ label:"Archivio clienti", linkTo:"/customer/list", icon:faUsers })
	if(api.auth.chk('admin'))
		menuGeo.push({ label:"Archivio agenti", linkTo:"/agent/list", icon:faSuitcase })
	if(api.auth.chkAny([ 'user' ]))
		menuGeo.push({ label:"Mappatura", linkTo:"/geomap", icon:faMapMarked })
	
	const menuItems = []
	if(api.auth.chk('items'))
	menuItems.push({ label:"Articoli", linkTo:"/item/list", icon:faBoxesStacked })
	
	const menuInd40 = []
	if(api.auth.chk('ind40'))
		menuInd40.push(
			{ label:"Macchine ind 4.0", linkTo:"/ind40/mech/list", icon:faConveyorBelt },
			{ label:"Coda lavori", linkTo:"/ind40/odl/list", icon:faConveyorBelt },
			{ label:"Coda tagli", linkTo:"/ind40/cut/list", icon:faConveyorBelt },
		)

	const menu = []
	menuGeo.length && menu.push(menuGeo)
	menuItems.length && menu.push(menuItems)
	menuInd40.length && menu.push(menuInd40)
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen menu={menu} toolAuth>
				{api.chkAuth() ? <Router /> : <HomePage />}
			</Screen>
		</APICtx.Provider>
	)
}