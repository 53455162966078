import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select } from '#Form'

const machineOpts = [
	{ value:'btm' },
	{ value:'simec1' },
	{ value:'simec2' },
	{ value:'kasto' },
]
const smeupDBOpts = [
	{ value:'bs' },
	{ value:'euro' },
]

export default function AgentsEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' macchina'
	const formDef = {
		_id,
		callSet: _id ? "ind40/mech/update" : "ind40/mech/create",
		callGet: _id && "ind40/mech/get",
	}
	return (
		<Tpl title={title} backTo="/ind40/mech/list">
			<Form {...formDef}>
				<Input label="Matricola" name="serialNumber" required />
				<Input label="Nome" name="label" required />
				<Input label="Indirizzo IP" name="ipAddress" />
				<Input label="indirizzo OPC-UA" name="opcuaAddress" />
				<Select label="Tipologia" name="machineType" options={machineOpts} emptyLabel="-- nessuna tipologia selezionata --" />
				<Select label="Database SMEUP" name="smeupDB" options={smeupDBOpts} emptyLabel="-- nessun db selezionato --" />
			</Form>
		</Tpl>
	)
}
