// import Skeleton from '@mui/material/Skeleton';
// import TableMui from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
// import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from '@mui/material';

// import BtnPad from '../BtnPad'
// import Button from '../Button'
import { DisplayUser } from '../Display'

const gfxCellAlignment = {
	datetime: 'center',
	date: 'center',
	money: 'right',
	number: 'right',
	bool: 'center',
	boolean: 'center',
	icon: 'center',
}
export const evalAlignment = def => def?.cellAlign || (def?.type && gfxCellAlignment.hasOwnProperty(def.type) ? gfxCellAlignment[def.type] : 'left')

export const calcValue = async (def, row) => {
	let value = def?.field ? row?.[def.field] : (typeof(def?.content)==='function' ? await def.content(row) : def.content)
	const tooltip = (typeof(def?.tooltip)==='function' ? def.tooltip(row) : def.tooltip)
	const iconColor = def?.iconColor || undefined

	switch(def?.type) {
		case 'datetime':
			if(value) {
				let dateObj = new Date(value)
				value = ("0" + dateObj.getDate()).slice(-2)
				value += '/' + ("0" + (dateObj.getMonth() + 1)).slice(-2)
				value += '/' + dateObj.getFullYear()
				value += ' ' + ("0" + dateObj.getHours()).slice(-2)
				value += ':' + ("0" + dateObj.getMinutes()).slice(-2)
				value += ':' + ("0" + dateObj.getSeconds()).slice(-2)
			}
		break
		case 'date':
			if(value) {
				let dateObj = new Date(value)
				value = ("0" + dateObj.getDate()).slice(-2)
				value += '/' + ("0" + (dateObj.getMonth() + 1)).slice(-2)
				value += '/' + dateObj.getFullYear()
			}
		break
		case 'money':
			if(value) {
				value = (value/10000)
					.toFixed(2)
					.split('.')
				let valueInt = value[0]
					.split('')
					.reduceRight((acc, cur, idx, arr) =>
						cur + ((arr.length-idx)%3===1 ? '.' : '') + acc)
				value = '€ ' + valueInt + ',' + value[1]
			}
			else
				value = '-'
		break
		case 'number':
			value = value ? (value/10000).toFixed(2).replace(/\./g, ",") : '-'
		break
		case 'bool':
		case 'boolean':
			const trueIcon = def.hasOwnProperty('trueIcon') ? def.trueIcon : faCheck
			const falseIcon = def.hasOwnProperty('falseIcon') ? def.falseIcon : faTimes
			const valueIcon = value ? trueIcon : falseIcon
			value = valueIcon ? <FontAwesomeIcon icon={valueIcon} size="lg" color={iconColor} /> : ''
		break;
		case 'multiline':
			value = value ? value.split('\n').map((row, rowIdx) =>
				(<Box key={rowIdx}>{row}</Box>)
			) : ' '
		break
		case 'icon':
			value = Boolean(value) && <FontAwesomeIcon icon={value} size="lg" color={iconColor} />
		break;
		case 'user':
			value = <DisplayUser uid={value} />
		break
		case 'color':
			value = (
				<Box sx={{
					bgcolor: value,
					border: '1px solid black',
					width: 100,
					textAlign: 'center',
				}}>
					{value || '-'}
				</Box>
			)
		break;
	}
	return (tooltip && value) ? <Tooltip title={tooltip}>{value}</Tooltip> : (value || ' ')
}
