import React from 'react'
import { useParams } from "react-router-dom"

import Form, { Input, Checkbox, ColorPicker } from '#Form'
import { TplWSidebar as Tpl } from '#Template'

export default function AgentEdit() {
	const { _id } = useParams()

	const formDef = {
		callGet: _id && [ "agent/get", _id ],
		callSet: [ "agent/set", _id ],
		idRedirect: _id => [ '/agent/edit', _id ],
	}
	return (
		<Tpl title="Scheda agente">
			<Form {...formDef}>
				<Input label="Codice" name="code" required disabled />
				<Input label="Denominazione" name="name" required disabled />
				<Checkbox label="Attivo di default" name="activeDefault" />
				<ColorPicker label="Colore" name="color" />
			</Form>
		</Tpl>
	)
}