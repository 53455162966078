import React, { useEffect, useState, useContext } from 'react'
import { Typography } from '@mui/material'
import Button from '#Button'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Search, { SearchText } from '#Search'
import { faQuestion, faSync, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const drawQty = (qty, measureUnit) => {
	if(qty === undefined)
		return <FontAwesomeIcon icon={faQuestion} />
	if(qty === null)
		return <FontAwesomeIcon icon={faTriangleExclamation} color='red' />
	return (qty/10000).toString() + ' ' + measureUnit
}

const ItemQty = ({ code, endpoint, measureUnit }) => {
	const api = useContext(APICtx)
	const [ qty, setQty ] = useState(undefined)

	const handleLoad = () => api.call('smeup/qa/' + endpoint, { itemID:code }).then(ret => {
		setQty(ret?.hasOwnProperty('qty') ? ret.qty : null)
	}).catch(() => setQty(null))

	return (
		<Typography>
			{drawQty(qty, measureUnit)}
			{ (qty===undefined || qty===null) && <Button icon={faSync} tooltip="Calcola valore" onClick={handleLoad} /> }
		</Typography>
	)
}

const tabDef = [
	{ label:"Codice", field:"code", isSortable:true },
	{ label:"Descrizione", field:"description" },
	{ label:"Disponibilità mag.01", cellAlign:'center', content:row => (
		<ItemQty code={row.code} endpoint="warehouse01/availability" measureUnit={row.measureUnit} />
	)},
	{ label:"Disponibilità mag.02", cellAlign:'center', content:row => (
		<ItemQty code={row.code} endpoint="warehouse02/availability" measureUnit={row.measureUnit} />
	)},
]

export default function ItemList() {
	const [ srcString, setSrcString ] = useState('')
	const [ ids, setIds ] = useState([])
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('item/list', { srcString }).then(setIds)

	useEffect(() => {
		handleRefresh()
	}, [ srcString ])

	const getRow = _id => api.call('item/get', { _id })

	return (
		<Tpl title="Lista articoli">
			<Search>
				<SearchText label="Cerca" name="src-string" onChange={setSrcString} />
			</Search>
			<DataList def={tabDef} rowActions={undefined} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</Tpl>
	)
}
