import React, { useState, useEffect, useContext } from 'react'
import GoogleMap from 'google-map-react'
import { Box, Button, Drawer } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Checkbox, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faThumbtack } from '@fortawesome/pro-solid-svg-icons'

import { TplWSidebar as Tpl } from '#Template'
import APICtx from '#api'
import { Tooltip, Typography } from '@mui/material'

const initCenter = [45.6380407,10.4070349]
const initZoom = 9
const heatZoom = 13

function MapPoint({ name, agent, revenues, code, addrLoc, addrProv, agents }) {
	const [ isSelected, setSelected ] = useState(false)

	let agentData = agents ? agents.filter(agRow => agRow.code===agent) : []
	agentData = agentData.length ? agentData[0] : {}
	let agentName = agent || 'nessuno'
	if(agentData.name)
		agentName += ' - ' + agentData.name
	const agentColor = agentData.color || 'black'

	const tooltipTitle = (
		<>
			<Typography>{code} - {name}</Typography>
			<Typography variant="subtitle2">Agente: {agentName}</Typography>
			<Typography variant="body2">Fatturato A.C.: {(revenues && revenues.yb0) || '0'} €</Typography>
			<Typography variant="body2">Fatturato A.P.: {(revenues && revenues.yb1) || '0'} €</Typography>
			<Typography variant="body2">Località: {addrLoc} - {addrProv}</Typography>
		</>
	)
	
	return (
		<Box sx={{ m:0, b:0, p:0, cursor:'pointer', fontSize:'2.6em' }}>
			<Tooltip title={tooltipTitle} arrow placement="top">
				<span className="fa-layers fa-fw" style={{ padding:0, margin:0 }}>
					{ isSelected && <FontAwesomeIcon size="sm" icon={faCircle} color={agentColor} /> }
					<FontAwesomeIcon
						inverse={isSelected}
						icon={faThumbtack}
						transform="shrink-8"
						onMouseEnter={() => setSelected(true)}
						onMouseLeave={() => setSelected(false)}
						color={agentColor}
					/>
				</span>
			</Tooltip>
		</Box>
	)
}

function OptsAgent({ code, name, color, isActive, setActive }) {
	const labelId = 'opts-agent-'+code

	const boxSx = {
		height: 20,
		width: 20,
		bgcolor: color,
		border: '1px solid black',
		display: 'inline-block',
		mr: 1,
	}
	const boxColor = (
		<>
			<Box sx={boxSx}></Box>
			{code + ' - ' + name}
		</>
	)
	return (
		<ListItem dense button onClick={() => setActive(!isActive)}>
			<ListItemIcon>
			<Checkbox
				edge="start"
				checked={isActive}
				tabIndex={-1}
				disableRipple
				inputProps={{ 'aria-labelledby': labelId }}
			/>
			</ListItemIcon>
			<ListItemText id={labelId} primary={boxColor} />
		</ListItem>
	)
}

function OptsAgents({ agents, agentsShow, setAgentsShow }) {
	const handleToggle = (code, valNew) => {
		const showIdx = agentsShow.indexOf(code)
		if(valNew && showIdx<0)
			setAgentsShow(agentsShow.concat(code))
		else if(!valNew && showIdx>=0) {
			const showNew = [...agentsShow]
			showNew.splice(showIdx, 1)
			setAgentsShow(showNew)
		}
	}
	return (
		<List>
			{agents.map(agent => (
				<OptsAgent
					key={agent._id}
					code={agent.code}
					name={agent.name}
					color={agent.color}
					isActive={agentsShow.includes(agent.code)}
					setActive={valNew => handleToggle(agent.code, valNew)}
				/>
			))}
		</List>
	)
} 

function OptsRevenues({ value, setValue }) {
	const handleChange = e => setValue(e.target.value)

	return (
		<FormControl component="fieldset">
			<FormLabel component="legend">Mostra</FormLabel>
			<RadioGroup
				aria-label="gender"
				value={value}
				name="radio-buttons-group"
				onChange={handleChange}
			>
				<FormControlLabel value="all" control={<Radio />} label="Tutti" />
				<FormControlLabel value="0" control={<Radio />} label="Solo con fatturato nell'anno corrente" />
				<FormControlLabel value="1" control={<Radio />} label="Solo con fatturato nell'anno corrente e precedente" />
				<FormControlLabel value="8" control={<Radio />} label="Solo con fatturato negli ultimi 8 anni" />
				<FormControlLabel value="-8" control={<Radio />} label="Nessun fatturato negli ultimi 8 anni" />
			</RadioGroup>
		</FormControl>
	)
}

function OptsDrawer({ label, open, onClose, children }) {
	return (
		<Drawer anchor="right" open={open} onClose={onClose}>
			<Box sx={{ p:1 }}>
				<Typography variant="h6">{label}</Typography>
				{children}
			</Box>
		</Drawer>
	)
}

export default function Geomap() {
	const [ customers, setCustomers ] = useState([])
	const [ heatData, setHeatData ] = useState(null)
	const [ mapBounds, setMapBounds ] = useState({})
	const [ agentsOpened, setAgentsOpened ] = useState(false)
	const [ revenuesOpened, setRevenuesOpened ] = useState(false)
	const [ agents, setAgents ] = useState([])
	const [ agentsShow, setAgentsShow ] = useState([])
	const [ revenues, setRevenues ] = useState('all')
	const api = useContext(APICtx)
	
	const aclSupervisor = api.auth.chkAny([ 'sede', 'euroacciai', 'friuli' ])

	useEffect(() => {
		api.call('geo/scanFull', { agents:agentsShow, revenues }).then(ret => {
			const heatData = ret.map(customer => {
				return {
					lat: customer.geo.latitude,
					lng: customer.geo.longitude,
				}
			})
			setHeatData(heatData)
		})
		// eslint-disable-next-line
	}, [ agentsShow, revenues ])

	useEffect(() => {
		if(mapBounds.zoom>=heatZoom) {
			api.call('geo/scan', {
				latMin: mapBounds.bounds.sw.lat,
				latMax: mapBounds.bounds.ne.lat,
				lngMin: mapBounds.bounds.sw.lng,
				lngMax: mapBounds.bounds.ne.lng,
				agents: agentsShow,
				revenues,
			}).then(setCustomers)
		}
		// eslint-disable-next-line
	}, [ mapBounds, agentsShow, revenues ])

	useEffect(() => {
		aclSupervisor && api.call('agent/load').then(agents => {
			setAgents(agents)
			setAgentsShow(agents.filter(agent => agent.activeDefault).map(agent => agent.code))
		})
		// eslint-disable-next-line
	}, [])

	const heatmap = heatData ? {
		positions: heatData,
		options: {
			radius: 12,
			opacity: (!mapBounds.zoom || mapBounds.zoom<heatZoom) ? 0.6 : 0,
			gradient: [
				"rgba(0, 255, 255, 0)",
				/*
				"rgba(0, 255, 255, 1)",
				"rgba(0, 191, 255, 1)",
				"rgba(0, 127, 255, 1)",
				"rgba(0, 63, 255, 1)",
				"rgba(0, 0, 255, 1)",
				"rgba(0, 0, 223, 1)",
				"rgba(0, 0, 191, 1)",
				*/
				"rgba(0, 0, 159, 1)",
				"rgba(0, 0, 127, 1)",
				"rgba(63, 0, 91, 1)",
				"rgba(127, 0, 63, 1)",
				"rgba(191, 0, 31, 1)",
				"rgba(255, 0, 0, 1)",
			],
			maxIntensity:6,
		},
	} : undefined

	const onChange = ({ center, zoom, bounds }) => setMapBounds({ center, zoom, bounds })

	const gmapParams = {
		center: initCenter,
		zoom: initZoom,
		options: {
			mapTypeControl: true,
			fullscreenControl: false,
			streetViewControl: true,
		},
		heatmap,
		onChange,
		bootstrapURLKeys: {
			key: "AIzaSyDCYdt2Z6phkTvnn-40wid567_20Q2ngV8",
			libraries: ['visualization'],
		}
	}

	return (
		<Tpl title="Home" fullScreen>
			<Box sx={{ mb:1 }} display="flex" justifyContent="right">
				{ aclSupervisor && (
					<Button variant="outlined" color="primary" onClick={() => setAgentsOpened(true)}>
						Agenti
					</Button>
				)}
				<Button variant="outlined" color="primary" onClick={() => setRevenuesOpened(true)}>
					Fatturato
				</Button>
			</Box>
			<OptsDrawer label="Agenti" open={agentsOpened} onClose={() => setAgentsOpened(false)}>
				<OptsAgents agents={agents} agentsShow={agentsShow} setAgentsShow={setAgentsShow} />
			</OptsDrawer>
			<OptsDrawer label="Fatturato" open={revenuesOpened} onClose={() => setRevenuesOpened(false)}>
				<OptsRevenues value={revenues} setValue={setRevenues} />
			</OptsDrawer>
			
			<Box sx={{ height:'85vh' }}>
			{ heatData && (
				<GoogleMap {...gmapParams}>
					{ mapBounds.zoom>=heatZoom && customers.map(customer => (
						<MapPoint
							key={customer._id}
							lat={customer.geo.latitude}
							lng={customer.geo.longitude}
							agents={agents}
							{...customer}
						/>
					))}
				</GoogleMap>
			)}
			</Box>
		</Tpl>
	)
}